<template>
  <div class="inventory-detail mt-5">
    <div class="pointer go-back mb-4 pl-1" @click="$router.push('/user/inventories')">
      <fa :icon="['fa', 'chevron-left']" />
      <span class="pl-2">返回</span>
    </div>
    <div class="inventory-detail__wrapper p-5">
      <header class="grid-a p-4">
        <h3 class="text-white">{{ nameObject.type }}{{ nameObject.name }}</h3>
        <h5 class="text-secondary">{{ nameObject.wear }}</h5>
      </header>

      <section class="grid-b inventory-detail__img p-4">
        <div class="d-flex justify-content-center">
          <img :src="imageUrl" alt="" />
        </div>
      </section>

      <section class="grid-c p-4 text-9">
        <div class="p-4 pb-5 d-flex align-items-start" style="border-radius:6px;background:#141a24">
          <ul class="timeline">
            <li class="timeline__row icon" :class="[{ done: step > 1 }]">
              <tick-svg v-if="step > 1" class="timeline__icon" />
              <doing-svg v-if="step === 1" class="timeline__icon" />
              <todo-svg v-if="step < 1" class="timeline__icon" />
              <div class="timeline__line"></div>
            </li>
            <li class="timeline__row icon" :class="[{ done: step > 2, error: inventory.error_name }]">
              <tick-svg v-if="step > 2 && !inventory.error_name" class="timeline__icon" />
              <doing-svg v-if="step === 2" class="timeline__icon" />
              <todo-svg v-if="step < 2" class="timeline__icon" />
              <error-svg v-if="step > 2 && inventory.error_name" class="timeline__icon" />
              <div class="timeline__line"></div>
            </li>
            <li class="timeline__row icon" :class="[{ done: step > 3, error: inventory.error_name }]">
              <tick-svg v-if="step > 3 && !inventory.error_name" class="timeline__icon" />
              <doing-svg v-if="step === 3" class="timeline__icon" />
              <todo-svg v-if="step < 3" class="timeline__icon" />
              <error-svg v-if="step > 3 && inventory.error_name" class="timeline__icon" />
              <div class="timeline__line"></div>
            </li>
            <li class="timeline__row icon end" :class="[{ done: step > 4, error: inventory.error_name }]">
              <tick-svg v-if="step > 4 && !inventory.error_name" class="timeline__icon" />
              <doing-svg v-if="step === 4" class="timeline__icon" />
              <todo-svg v-if="step < 4" class="timeline__icon" />
              <error-svg v-if="step > 4 && inventory.error_name" class="timeline__icon" />
            </li>
          </ul>

          <ul class="timeline">
            <li class="timeline__row" :class="[{ done: step > 1, doing: step === 1, todo: step < 1 }]">
              <div class="pl-2">
                <div class="label">发起提货</div>
                <div class="time text-9" v-if="step > 1 && createAt">
                  {{ createAt }}
                </div>
              </div>
            </li>
            <li class="timeline__row" :class="[{ done: step > 2, doing: step === 2, todo: step < 2 }]">
              <div class="pl-2">
                <div class="label" v-if="step > 2 && !inventory.error_name">卖家已发货</div>
                <div class="label text-warning" v-if="inventory.error_name">发货失败，{{ inventory.error_name }}</div>
                <div class="label" v-if="step === 2">等待卖家发货</div>
                <div class="time text-9" v-if="step > 2 && acceptAt">
                  {{ acceptAt }}
                </div>
              </div>
            </li>
            <li class="timeline__row" :class="[{ done: step > 3, doing: step === 3, todo: step < 3 }]">
              <div class="pl-2">
                <div class="label" v-if="step < 3">等待买家接收</div>
                <div class="label" v-if="step === 3">卖家已发货，请接收</div>
                <div v-if="step === 3" class="text-secondary">
                  <span>请完成收货</span>
                  <span v-if="user.info.steam_id">
                    ,
                    <a :href="`https://steamcommunity.com/profiles/${user.info.steam_id}/tradeoffers/`">点击这里</a>
                    去接收
                  </span>
                </div>
                <div v-if="step > 3 && !inventory.error_name" class="text-white">已接收</div>
                <div v-if="step > 3 && inventory.error_name" class="text-white">接收失败</div>
                <!-- <div class="time text-9" v-if="step > 3 && acceptAt">
                  {{ acceptAt }}
                </div> -->
              </div>
            </li>
            <li class="timeline__row end" :class="[{ done: step > 4, doing: step === 4, todo: step < 4 }]">
              <div class="pl-2">
                <div class="label text-warning" v-if="inventory.error_name">提取失败，{{ inventory.error_name }}</div>
                <div class="label" v-if="!inventory.error_name && step <= 4">提取中</div>
                <div class="label" v-if="!inventory.error_name && step > 4">已提取</div>
                <div class="time text-9" v-if="step > 4 && completeAt">
                  {{ completeAt }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { nameFilter, errorParser } from '@/utils'
import { ASSET_URL } from '@/config'
import { UserService } from '@/services/api'

export default {
  name: 'InventoryDetail',
  data() {
    return {
      inventory: {},
      step: -1,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    id() {
      return this.$router.currentRoute.params.id
    },
    item() {
      return this.inventory.item
    },
    price() {
      return this.inventory.price
    },
    nameObject() {
      return nameFilter(this.item?.name)
    },
    imageUrl() {
      return ASSET_URL + this.inventory.item?.image_url
    },
    createAt() {
      if (this.inventory && this.inventory.order_time_frame && this.inventory.order_time_frame.order_create_at) {
        return new Date(this.inventory.order_time_frame.order_create_at).toLocaleString()
      }
      return ''
    },
    cancelAt() {
      if (this.inventory && this.inventory.order_time_frame && this.inventory.order_time_frame.order_cancel_at) {
        return new Date(this.inventory.order_time_frame.order_cancel_at).toLocaleString()
      }
      return ''
    },
    completeAt() {
      if (this.inventory && this.inventory.order_time_frame && this.inventory.order_time_frame.order_complete_at) {
        return new Date(this.inventory.order_time_frame.order_complete_at).toLocaleString()
      }
      return ''
    },
    processAt() {
      if (this.inventory && this.inventory.order_time_frame && this.inventory.order_time_frame.order_wait_process_at) {
        return new Date(this.inventory.order_time_frame.order_wait_process_at).toLocaleString()
      }
      return ''
    },
    acceptAt() {
      if (this.inventory && this.inventory.order_time_frame && this.inventory.order_time_frame.order_wait_accept_at) {
        return new Date(this.inventory.order_time_frame.order_wait_accept_at).toLocaleString()
      }
      return ''
    },
  },
  mounted() {
    this.hydratePage()
  },
  methods: {
    toast(message, type = 'warning') {
      let msg = message
      if (type === 'danger') {
        msg = errorParser(message).message
      }
      this.$bvToast.toast(msg, {
        title: msg,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    hydratePage() {
      this.loading = true
      UserService.getInventoryDetail(this.id)
        .then(({ data }) => {
          this.inventory = data
          if (data.order_time_frame) {
            if (data.order_time_frame.order_create_at) {
              // 已发起提货
              this.step = 2
            }
            if (data.order_time_frame.order_wait_process_at) {
              // 等卖家发货
              this.step = 2
            }
            if (data.order_time_frame.order_wait_accept_at) {
              // 卖家已发货，等待接收
              this.step = 3
            }
            if (data.order_time_frame.order_cancel_at) {
              this.step = 5
            }
            if (data.order_time_frame.order_complete_at) {
              // 买家接收时间
              this.step = 5
            }
            if (data.error_name) {
              this.step = 5
            }
          }
        })
        .catch(e => {
          this.toast(e.response, 'danger')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index.scss';

.inventory-detail {
  min-height: 500px;
  max-width: 1200px;
  margin: 0 auto;
}

.inventory-detail__wrapper {
  background: #25304333;

  display: grid;
  grid-template-areas:
    'A B'
    'C B';
  grid-template-rows: min-content 1fr;
  @media (min-width: 62em) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

.go-back {
  color: #aaa;
  &:hover {
    color: #fff;
  }
}

.inventory-detail__img {
  border-left: 2px dashed #666;

  img {
    pointer-events: none;
    user-select: none;
  }
}

.grid-a {
  grid-area: A;
}
.grid-b {
  grid-area: B;
}
.grid-c {
  grid-area: C;
}

.timeline {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.timeline__row {
  /* width: 50px; */
  position: relative;
}
.timeline__row.icon {
  width: 22px;
}
.timeline__row:not(.end) {
  height: 68px;
}
.timeline__row.end {
  height: 22px;
}
.timeline__row {
  .label {
    color: #fff;
  }
  .time {
    color: $secondary;
  }
}
.timeline__row.doing {
  .label {
    color: $primary;
  }
}
.timeline__row.todo {
  .label {
    color: #303a4e;
  }
}

.timeline__row.done {
  .timeline__line {
    background-color: #407134;
  }
}
.timeline__row.error {
  .timeline__line {
    background-color: #d13434;
  }
}

.timeline__icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.timeline__line {
  width: 4px;
  height: 90%;
  background-color: #303a4e;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
